import { Button, styled } from "@mui/material";
import { Box } from "@mui/system";
export interface Props {
  active?: Boolean;
}

export const Tab = styled(Box)`
  background: linear-gradient(90deg, #8259bd 0%, #340c6f 100%);
  height: 0.3rem;
  width: ${(p: Props) => (p.active ? "100%" : "0%")};
  border-radius: 0.7rem 0.7rem 0 0;
  transition: all 0.6s ease-in-out;
`;
export const Label = styled(Box)`
  padding: 0 1rem 0.2rem;
  color: ${(p: Props) => (p.active ? "#000" : "rgba(0, 0, 0, 0.3)")};
  transition: all 0.6s ease-in-out;
`;

export const ItemContainer = styled(Button)<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  cursor: pointer;
  pointer-events: ${(props) => (props.active ? "none" : "visible")};
  line-height: unset;
  text-transform: unset;
  min-width: unset;
  padding: 0.3rem 0 0 0;
  font-size: 1rem;
  outline: unset;
`;
