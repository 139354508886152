import { Box, Grid, SxProps, Theme } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { MdMoreVert } from "react-icons/md";
import { InstallmentCardProps } from ".";
import { currency, dateFormat } from "../../Utils";
import { Card } from "../Card/Card";
import { CheckBox } from "../CheckBox";
import { StatusTag } from "../StatusTag";
import {
  cardContainerStyle,
  CardHeader,
  CardItemLabel,
  CardItemValue,
  MenuButton,
  TopAlert,
  TopAlertDiscount,
} from "./installmentCard.styles";

const dueDays = 2000;
export function InstallmentCard({
  status,
  contract,
  value,
  dueDate,
  onClickMoreButton,
  onSelect,
  installment,
  checked = false,
  storeId,
}: InstallmentCardProps) {
  const [isChecked] = useState(checked);
  console.log(dueDays);
  const cardItems = [
    {
      key: "Contrato",
      value: `${storeId ?? " "}-${contract}`,
    },
    {
      key: "Valor",
      value: currency(installment.installment_value),
    },
    {
      key: "Vencimento",
      value: dateFormat(dueDate),
    },
    {
      key:
        installment.status === "Pago"
          ? "Data do pagamento"
          : installment.discount < 0
          ? "Valor com desconto"
          : "Valor atualizado",
      value:
        installment.status === "Pago"
          ? dateFormat(installment.payment_date ? installment.payment_date : "")
          : currency(installment.total),
      hidden:
        status !== "Pago" && moment(moment()).diff(dueDate, "days") > dueDays,
    },
    // {
    //   key: "Parcialmente pago",
    //   value: currency(installment.amortized_value)
    // },
  ];

  function handleSelect(value: boolean) {
    onSelect(value);
  }

  function handleOpenModal() {
    onClickMoreButton();
  }
  return (
    <Card
      sx={
        {
          ...cardContainerStyle,
          paddingTop: "10px",
          border:
            moment(moment()).diff(dueDate, "days") < dueDays
              ? "2px solid"
              : "none",
          borderColor: isChecked || checked ? "success.main" : "transparent",
        } as SxProps<Theme>
      }
    >
      <>
        {status !== "Pago" &&
          moment(moment()).diff(dueDate, "days") > dueDays && (
            <TopAlert>PROCURE A LOJA PARA REGULARIZAR ESSA FATURA !</TopAlert>
          )}
        {installment.discount < 0 && (
          <TopAlertDiscount>
            PAGUE AGORA COM {currency(installment.discount * -1)} DE DESCONTO !
          </TopAlertDiscount>
        )}
        {/* <Box>top</Box> */}
        {status !== "Pago" &&
          moment(moment()).diff(dueDate, "days") < dueDays && (
            <CardHeader isChecked={isChecked || checked}>
              <CheckBox
                checked={isChecked || checked}
                onChange={(value) => {
                  handleSelect(value);
                }}
              />
              <MenuButton onClick={handleOpenModal}>
                <MdMoreVert />
              </MenuButton>
            </CardHeader>
          )}
        <Grid container sx={{ paddingX: "13px", paddingBottom: "13px" }}>
          <Grid item xs={8} md={9}>
            <Grid container sx={{ alignItems: "center", height: "100%" }}>
              {cardItems.map(
                (item, key) =>
                  !item.hidden && (
                    <Grid item xs={6} md={4} key={key}>
                      <CardItemLabel>{item.key}</CardItemLabel>
                      <CardItemValue>{item.value}</CardItemValue>
                    </Grid>
                  )
              )}
              {installment.amortized_value > 0 &&
                installment.status !== "Pago" && (
                  <Box>
                    <CardItemLabel>Parcialmente Pago:</CardItemLabel>
                    <CardItemValue>
                      {currency(installment.amortized_value)}
                    </CardItemValue>
                  </Box>
                )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            md={3}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent:
                status === "Pago" ||
                moment(moment()).diff(dueDate, "days") > dueDays
                  ? "space-between"
                  : "center",
              flexDirection: "column",
            }}
          >
            {(status === "Pago" ||
              moment(moment()).diff(dueDate, "days") > dueDays) && (
              <MenuButton onClick={handleOpenModal}>
                <MdMoreVert />
              </MenuButton>
            )}
            <StatusTag
              status={status}
              sx={{
                marginBottom:
                  status === "Pago" ||
                  moment(moment()).diff(dueDate, "days") > dueDays
                    ? "0.7rem"
                    : "0",
                marginTop:
                  status === "Pago" ||
                  moment(moment()).diff(dueDate, "days") > dueDays
                    ? "1.42rem"
                    : "0",
              }}
            />
          </Grid>
        </Grid>
      </>
    </Card>
  );
}
