import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { InstallmentsProvider } from "./hooks/useInstallments";
import { LoginProvider } from "./hooks/useLogin";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <LoginProvider>
      <InstallmentsProvider>
        <App />
      </InstallmentsProvider>
    </LoginProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register();


