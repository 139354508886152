import { Box, Button, styled, Typography } from "@mui/material";

export const ContainerPayment = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 1rem
`;
export const ResumItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
`;
export const MethodsList = styled(Box)`
  display: Grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin: 1rem 0;
`;
export const TotalValue = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  opacity: 0.6;
  padding: 0 1rem 0;
`;
export const Icon = styled(Box)`
  font-size: 2rem;
  margin: 0;
  padding: 0;
  line-height: 0.9rem;
  width: 30px;
  height: 30px;
`;
export const PaymentItemList = styled(Button)<{checked:boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: ${props => props.checked ? `0px 0px 0px 2px ${props.theme.palette.primary.main} inset` : "0px 0px 0px 1px #3810734D inset" } ;
  border-radius: 0.71rem;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: unset;
  text-transform: unset;
  min-width: unset;
  padding: 0.6rem;
  outline: unset;
`;

export const PaymentTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1.1rem;
  color: ${(props) => props.theme.palette.primary.main};
`;
export const CloseButton = styled(Button)`
  font-size: 1.5rem;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.3rem;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  color: ${(props) => props.theme.palette.primary.main};
  padding: 0;
`;
