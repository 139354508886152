import { Box, styled, Typography } from "@mui/material";
import { Card } from "../../Components/Card/Card";

export const Container = styled(Box)`
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto;
  height: fit-content;
  overflow-y: auto;
  flex-grow: 1;
  max-width: 700px;
  height: calc(100% - 4.8rem - 3.3rem);
  padding: 1rem;
  /* padding: 0 1.2rem 3.2rem; */
  margin: 0 auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.01);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.04);
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.01);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;

export const PullMsg = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  overflow-y: auto;
  margin: 1rem auto;
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.3;
  text-align: center;
`;
export const CardInputContanier = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 1.4rem;
  @media (max-width: 700px) {
    flex-direction:column ;
  }
`;

export const Carddetail = styled(Card)`
  padding: 1rem;
`;
export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column ;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 1.1rem;
  position: fixed;
  background: ${props=> props.theme.palette.background.default} ;
  z-index:999999999999999999999999 ;
  left: 0;
  width: 100%;
  margin-top: -1px;
`;
export const InstallmentsPageTitle = styled(Typography)`
  font-weight: 500;
  opacity: 0.3;
  text-align: center;
  font-size: 1rem;
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.2rem;
  }
`;
export const InstallmentsPageTitleBox = styled(Typography)`
  font-weight: 500;
  opacity: 0.3;
  font-size: 0.9rem;
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.2rem;
  }
  text-align: center ;
  margin-bottom: 1rem;
`;

export const HeaderItemsLabel = styled(Typography)`
  font-weight: 500;
  font-size: 1rem;
  opacity: 0.3;
`;

export const HeaderItemsValue = styled(Typography)`
  font-weight: 700;
  font-size: 1.2rem;
  color: ${(props) => props.theme.palette.primary.main};
`;
export const TotalValueText = styled(Typography)`
  font-weight: 500;
  font-size: 1rem;
`;

export const DialogTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.palette.primary.main};
`;
export const PaymentTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1.1rem;
  color: ${(props) => props.theme.palette.primary.main};
`;
export const ContainerTitle = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
export const ContainerResum = styled(Box)`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
`;

export const ContainerPayment = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const ResumItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4rem;
`;
export const MethodsList = styled(Box)`
  display: Grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;
export const TotalValue = styled(Box)`
  display: flex;
  justify-content: space-between;
  opacity: 0.6;
`;
export const Icon = styled(Box)`
  font-size: 2rem;
  margin: 0;
  padding: 0;
  line-height: 0.9rem;
  width: 30px;
  height: 30px;
`;

