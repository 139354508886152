import { Dialog, Grid } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import Skeleton from "@mui/material/Skeleton";
import { useInstallments } from "../../hooks/useInstallments";
import { dateFormat, currency } from "../../Utils";
import { InstallmentInList } from "../InstallmentInList";
import { CloseButton, HeaderItemsLabel, HeaderItemsValue } from "./ContratModal.styles";
import { ContractModalProps } from "."
import { installment } from "../../types";
export function ContractModal({ open, onClose }: ContractModalProps) {
  const {
    selectedInstallments,
    addSelectedInstallment,
    removeSelectedInstallment,
  } = useInstallments();
  const { contract, cleanContract } = useInstallments();

  const handleClose = () => {
    console.log(contract)
    cleanContract();
    onClose();
  };

  const HeaderItems = [
    {
      key: "Contrato",
      value: contract?.id
    },
    {
      key: "Valor",
      value: contract?.contract_value ? currency(contract?.contract_value) : "-----"
    },
    {
      key: "Data",
      value: contract?.contract_date ? dateFormat(contract.contract_date) : "-----"
    },
    {
      key: "Filial",
      value: contract?.store_id ? "Loja "  + contract.store_id : "-----"
    },
  ]

  function installmentClick(value: boolean, i: installment){
    if (value) {
      addSelectedInstallment(i)
    } else {
      removeSelectedInstallment(i)
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="contract-dialog-title"
        aria-describedby="contract-dialog-description"
        fullWidth={true}
        maxWidth="sm"
        sx={{ zIndex: 999999999999999999999 }}
      >
        <Grid container sx={{ zIndex: 9999999,padding: "1rem" }}>
          <Grid item xs={10}>
            <Grid
              container
              spacing={1}
              sx={{ alignItems: "center", height: "100%" }}
            >
              {
              contract ?
              HeaderItems.map((item, key) => <Grid key={item.key + key} item xs={6} md={4}>
                <HeaderItemsLabel>
                  {item.key}
                </HeaderItemsLabel>
                <HeaderItemsValue>
                  {contract ? item.value : <Skeleton variant="text" />}
                </HeaderItemsValue>
              </Grid>) : <div></div>}
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <CloseButton onClick={handleClose} >
              <MdOutlineClose />
            </CloseButton>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ padding: "1rem", paddingTop: "0", paddingBottom: "3rem" }}
        >
          <HeaderItemsLabel>
            Parcelas:
          </HeaderItemsLabel>
          <Grid item xs={12}>
            <Grid container sx={{ maxHeight: "300px", overflow: "auto" }}>
              {contract ? (
                contract.installments.map((i, key) => (
                  <InstallmentInList checked={selectedInstallments.findIndex(x => x.installment_id === i.installment_id) >= 0} onSelect={(value) => installmentClick(value, i)} installment={i} key={i.installment_id} />
                ))
              ) : (
                <>
                  <Skeleton variant="rectangular" width={"100%"} height={50} />
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    sx={{ mt: "10px" }}
                    height={50}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div >
  );
}