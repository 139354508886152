import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Installments } from "../Pages/Installments";
import { Login } from "../Pages/Login";
import { PaymentOrder } from "../Pages/PaymentOrder";
import { PaymentOrderList } from "../Pages/PaymentOrderList";
import { PaymentPage } from "../Pages/PaymentPage";
import { LoginRoute } from "./LoginRoute";
import { PrivateRoute, PrivateRouteWrapper } from "./PrivateRoute";
export function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/"
            element={
              <PrivateRouteWrapper>
                <Installments />
              </PrivateRouteWrapper>
            }
          />
        </Route>
        <Route path="/ordem-de-pagamento/:paymentId" element={<PrivateRoute />}>
          <Route
            path="/ordem-de-pagamento/:paymentId"
            element={
                <PaymentOrder />
            }
          />
        </Route>
        <Route path="/pagar/:paymentId" element={<PaymentPage />}/>
        <Route path="/ordem-de-pagamento/" element={<PrivateRoute />}>
          <Route
            path="/ordem-de-pagamento/"
            element={
                <PaymentOrderList />
            }
          />
        </Route>
        <Route path="/login" element={<LoginRoute />}>
          <Route
            path="/login"
            element={
              <Login/>
            }
          />
        </Route>
        <Route path="*" element={<>not found</>} />
      </Routes>
    </BrowserRouter>
  );
}
