import { Dialog, Grid } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import Skeleton from "@mui/material/Skeleton";
import { dateFormat, currency } from "../../Utils";
import {
  CloseButton,
  HeaderItemsLabel,
  HeaderItemsValue,
} from "./PaymentModal.styles";
import { PaymentModalProps } from "./PaymentModal.types";
import { InstallmentInListPayment } from "../InstallmentInListPayment";
import moment from "moment";

export function PaymentModal({ open, onClose, payment }: PaymentModalProps) {
  const handleClose = () => {
    onClose();
  };

  const HeaderItems = [
    {
      key: "Valor",
      value: payment?.payment_amount
        ? currency(payment.payment_amount)
        : "-----",
    },
    {
      key: "Data de pagamento",
      value: payment?.updatedAt ? moment(payment.createdAt).format('DD/MM/YYYY HH:mm:ss') : "-----",
    },
    {
      key: "Forma de pagamento",
      value: payment?.payment_method ? payment.payment_method : "-----",
    },
  ];

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="contract-dialog-title"
        aria-describedby="contract-dialog-description"
        fullWidth={true}
        maxWidth="sm"
        sx={{ zIndex: 999999999999999999999 }}
      >
        <Grid container sx={{ zIndex: 9999999, padding: "1rem" }}>
          <Grid item xs={10}>
            <Grid
              container
              spacing={1}
              sx={{ alignItems: "center", height: "100%" }}
            >
              {payment ? (
                HeaderItems.map((item, key) => (
                  <Grid key={item.key + key} item xs={6} md={4}>
                    <HeaderItemsLabel>{item.key}</HeaderItemsLabel>
                    <HeaderItemsValue>
                      {payment ? item.value : <Skeleton variant="text" />}
                    </HeaderItemsValue>
                  </Grid>
                ))
              ) : (
                <div></div>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <CloseButton onClick={handleClose}>
              <MdOutlineClose />
            </CloseButton>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ padding: "1rem", paddingTop: "0", paddingBottom: "3rem" }}
        >
          <HeaderItemsLabel>Parcelas:</HeaderItemsLabel>
          <Grid item xs={12}>
            <Grid container sx={{ maxHeight: "300px", overflow: "auto" }}>
              {payment ? (
                payment.installments.map((i, key) => (
                  <InstallmentInListPayment
                    onSelect={(value) => {}}
                    installment={{
                      ...i,
                      status:
                        i.total === 0
                          ? "Pago"
                          : i.expired_days > 0
                          ? "Vencida"
                          : "Em aberto",
                    }}
                    key={i.installment_id}
                  />
                ))
              ) : (
                <>
                  <Skeleton variant="rectangular" width={"100%"} height={50} />
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    sx={{ mt: "10px" }}
                    height={50}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
