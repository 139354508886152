import React, { useEffect, useState } from "react";
import { ContractModal } from "../../Components/ContractModal";
import { InstallmentCard } from "../../Components/InstallmentCard";
import { useInstallments } from "../../hooks/useInstallments";
import { installment } from "../../types";
import Skeleton from "@mui/material/Skeleton";
import {
  Container,
  TitleContainer,
  InstallmentsPageTitle,
  PullMsg,
  InstallmentsPageTitleBox,
} from "./Installments.styles";
import PullToRefresh from "react-simple-pull-to-refresh";
import { Alert, Box, Snackbar } from "@mui/material";
import moment from "moment";

interface presetationProps {
  text: string;
  date: string;
  installments: installment[] | undefined;
}

export function Installments() {
  const {
    installlmentsInView,
    getContract,
    openInstallments,
    paidInstallments,
    getClient,
    addSelectedInstallment,
    selectedInstallments,
    removeSelectedInstallment,
    reRenderInstallments,
    getInstallments,
    getClientLogin,
    alertIsOpen,
    closeAlert,
  } = useInstallments();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    getClientLogin();
  }, []);

  const presetation: presetationProps[] = [
    {
      text: getInstallments().openInstallments
        ? getInstallments().openInstallments?.length > 0
          ? "Selecione as parcelas que você gostaria de pagar"
          : "Você não tem nenhuma parcela em aberto"
        : "Carregando suas parcelas...",
      date: getInstallments().date,
      installments: getInstallments().openInstallments,
    },
    {
      text: getInstallments().paidInstallments
        ? getInstallments().paidInstallments?.length > 0
          ? "Faturas pagas nos útimos 90 dias"
          : "Você ainda não tem nenhuma parcela paga nos últimos 90 dias."
        : "Carregando suas parcelas...",

      date: getInstallments().date,
      installments: getInstallments().paidInstallments,
    },
  ];

  function handleClickMoreButton(contractId: number, id: number) {
    setOpen(true);
    getContract(contractId, id);
  }
  function closeFunction() {
    reRenderInstallments();
  }
  return (
    <PullToRefresh
      onRefresh={() =>
        new Promise(function (resolve, reject) {
          // do a thing, possibly async, then…
          getClient();
          if (true) {
            resolve("Stuff worked!");
          } else {
            reject(Error("It broke"));
          }
        })
      }
      pullDownThreshold={85}
      resistance={2.5}
      pullingContent={<PullMsg>Puxe para atualizar!</PullMsg>}
    >
      <Container>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={alertIsOpen}
          autoHideDuration={10000}
          onClose={closeAlert}
          style={{ zIndex: "99999999999999999999999999999999999", marginBottom: "1rem" }}
          message="Pague ou selecione a parcela anterior para poder selecionar essa parcela."
        >
          <Alert
            onClose={closeAlert}
            severity="error"
            sx={{ width: "100%", boxShadow: "0px 0px 25px 0px rgba(0,0,0, 0.3)" }}
          >
            Pague ou selecione a parcela anterior para poder selecionar essa parcela.
          </Alert>
        </Snackbar>
        <TitleContainer>
          <InstallmentsPageTitle>
            {/* Última atualização em : 03/06/2022 ás 18:32 */}
            Última atualização em:{" "}
            {moment(presetation[installlmentsInView].date).format(
              "DD/MM/YYYY - HH:mm"
            )}
          </InstallmentsPageTitle>
        </TitleContainer>
        <Box sx={{ maxWidth: "700px", margin: "4rem auto 0" }}>
          <InstallmentsPageTitleBox>
            {presetation[installlmentsInView].text}
          </InstallmentsPageTitleBox>
          {paidInstallments && openInstallments ? (
            <>
              {presetation[installlmentsInView].installments?.map(
                (installment, key) => (
                  <InstallmentCard
                    onClickMoreButton={() => {
                      handleClickMoreButton(
                        installment.contract_id,
                        installment.store_id
                      );
                    }}
                    installment={installment}
                    storeId={installment.store_id}
                    key={installment.installment_id}
                    status={installment.status}
                    contract={installment.contract_id}
                    value={installment.installment_value}
                    dueDate={installment.due_date}
                    checked={
                      selectedInstallments.findIndex(
                        (x) => x.installment_id === installment.installment_id
                      ) >= 0
                    }
                    onSelect={(value) => {
                      if (value) {
                        addSelectedInstallment(installment);
                      } else {
                        removeSelectedInstallment(installment);
                      }
                    }}
                  />
                )
              )}
            </>
          ) : (
            <>
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={127}
                sx={{ borderRadius: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                width={"100%"}
                sx={{ mt: "2rem", borderRadius: "10px" }}
                height={127}
              />
              <Skeleton
                variant="rectangular"
                width={"100%"}
                sx={{ mt: "2rem", borderRadius: "10px" }}
                height={127}
              />
            </>
          )}
        </Box>

        <ContractModal
          onClose={() => {
            setOpen(false);
            closeFunction();
          }}
          open={open}
        />
      </Container>
    </PullToRefresh>
  );
}
