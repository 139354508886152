import { Icon } from "@mui/material";
import { useState } from "react";
import { SelectCardProps } from ".";
import {
  ContainerPayment,
  MethodsList,
  PaymentItemList,
  PaymentTitle,
} from "./SelectCard.styles";
import pix from "../../Assets/pix.svg";
import { BiBarcodeReader } from "react-icons/bi";
import { useInstallments } from "../../hooks/useInstallments";

const cpfs = [
  "056.829.663-09",
  "043.945.963-00",
  "029.484.753-76",
  "048.275.393-59",
];

export function SelectCard({ onSelect }: SelectCardProps) {
  const [isChecked, setIsChecked] = useState("");
  const { client } = useInstallments();

  function handleSelect(value: string) {
    onSelect(value);
    setIsChecked(value);
  }
  return (
    <ContainerPayment>
      <PaymentTitle>Selecione a forma de pagamento</PaymentTitle>
      <MethodsList>
        <PaymentItemList
          checked={isChecked === "PIX"}
          onClick={() => handleSelect("PIX")}
        >
          <Icon>
            <img width={"22px"} src={pix} alt="pix logo" />
          </Icon>
          Pix
        </PaymentItemList>
        <PaymentItemList
          checked={isChecked === "BILLET"}
          onClick={() => handleSelect("BILLET")}
        >
          <Icon>
            <BiBarcodeReader />
          </Icon>
          Boleto
        </PaymentItemList>
        {/* <PaymentItemList checked={isChecked==='CREDIT_CARD'} onClick={()=>handleSelect('CREDIT_CARD')}>
            <Icon>
                <BiCreditCard />
              </Icon>
              Cartão de crédito
            </PaymentItemList> */}
        {/* <PaymentItemList checked={isChecked==='DEBIT_CARD'} onClick={()=>handleSelect('DEBIT_CARD')}>
              <Icon>
                <MdCreditCard />
              </Icon>
              Cartão de débito
            </PaymentItemList> */}
      </MethodsList>
    </ContainerPayment>
  );
}
