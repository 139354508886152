import { Box } from "@mui/material";
import { useState } from "react";
import { ItemProps, TabProps } from ".";
import { useInstallments } from "../../hooks/useInstallments";
import { ItemContainer, Label, Tab } from "./tab.styles";



function Item({ label, active, onClick }: ItemProps) {
  return (
    <ItemContainer
      onClick={onClick}
    >
      <Label active={active}>{label}</Label>
      <Tab active={active}></Tab>
    </ItemContainer>
  );
}



export function Tabs({ tabs }: TabProps) {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { changeInstallmentsInView } = useInstallments();

  function handleClick(id: number) {
    setSelectedTab(id);
    changeInstallmentsInView(id)
  }

  return (
    <Box sx={{ display: "flex" }}>
      {tabs.map((tab, key) => (
        <Item
          key={key}
          label={tab}
          active={selectedTab === key}
          onClick={() => handleClick(key)}
        />
      ))}
    </Box>
  );
}
