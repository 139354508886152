import React from "react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as ThemeStyled } from "styled-components";

import { theme } from "./styles/theme";
import GlobalStyles from "./styles/global";
import { Navigation } from "./Routes/Routes";

function App() {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ThemeStyled theme={theme}>
          <GlobalStyles />
          <Navigation />
        </ThemeStyled>
      </ThemeProvider>
    </>
  );
}

export default App;
