import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const Container = styled(Button)`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: inherit;
  color: inherit;
  line-height: unset;
  text-transform: unset;
  min-width: unset;
  padding: 0;
  outline: unset;
`;
export const Label = styled.span`
  margin-left: 0.3rem;
`;
