import { useState } from "react";
import {
  MdRadioButtonUnchecked,
  MdCheckCircle,
} from "react-icons/md";
import { CheckBoxProps } from ".";
import { Container, Label } from "./CheckBox.style";

export function CheckBox({ onChange, hiddenLabel, checked=false }: CheckBoxProps) {

  const [isChecked, ] = useState(checked);

  function HandleClick(val: boolean) {
    // setIsChecked(val)
    onChange(val)
  }

  return (
    <Container onClick={() => HandleClick(!checked)}>
      {
        isChecked || checked ? (
          <>
            <MdCheckCircle />
            {
              !hiddenLabel &&
              <Label>Selecionado</Label>}
          </>) : (
          <>
            <MdRadioButtonUnchecked />
            {
              !hiddenLabel &&
              <Label>Selecione</Label>
            }
          </>)
      }
    </Container >
  )
}