import { Box, styled, Typography } from "@mui/material";
interface containerProps{
  matches: boolean; 
  isInViewer: boolean
}
export const Container = styled(Box)<containerProps>`
  display: ${(props) => (props.isInViewer ? "flex" : "none")};
  justify-content: space-between;
  background: ${(props) => props.theme.palette.primary.dark};
  position: fixed;
  bottom: ${(props) => (props.matches ? "3.3rem" : 0)};
  width: 100%;
  height: 3.858rem;
  color: white;
  padding: 1rem;
`;
export const ContainerInside = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 43.75rem;
  margin: 0 auto;
`;

export const LabelText = styled(Typography)`
  font-size: 1rem;
  margin-right: 0.7rem;
`;
export const ContainerText = styled(Box)`
  font-size: 1.5rem;
  flex-direction: row;
  align-items: flex-end;
  display: flex;
`;
export const ValueText = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.8rem;
`;
