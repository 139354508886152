import { Box } from "@mui/material";
import moment from "moment";
import { InstallmentInListProps } from ".";
import { currency } from "../../Utils";
import { CheckBox } from "../CheckBox";
import { StatusTag } from "../StatusTag";
import {
  CardItemLabel,
  CardItemValue,
  CheckContainer,
  Container,
} from "./InstallmentInListPayment.styles";

const dueDays = 180;

export function InstallmentInListPayment({
  installment,
  onSelect,
  checked = false,
}: InstallmentInListProps) {
  const cardItems = [
    {
      key: "Valor",
      value: currency(installment.total),
    },
    {
      key: installment.status === "Pago" ? "Pago em" : "Vencimento",
      value:
        installment.status === "Pago"
          ? moment(installment.payment_date).format("DD/MM/YYYY")
          : moment(installment.due_date).format("DD/MM/YYYY"),
    },
    {
      key: "Contrato",
      value: installment.store_id + "-" + installment.contract_id,
    },
  ];
  function handleSelect(value: boolean) {
    onSelect(value);
  }

  return (
    <Container>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "grid",
              alignItems: "center",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            {cardItems.map((item, key) => (
              <Box key={key}>
                <CardItemLabel>{item.key}</CardItemLabel>
                <CardItemValue>{item.value}</CardItemValue>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
