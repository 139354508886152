import { Box, ListItemIcon, MenuItem, Button } from "@mui/material";
import { FiLogOut } from "react-icons/fi";
import { logout } from "../../services/auth";
import { useInstallments } from "../../hooks/useInstallments";
import { hiddenCpf } from "../../Utils";
import Skeleton from "@mui/material/Skeleton";
import {
  Container,
  ProfileCpfContainer, ProfileNameContainer,
  ProfileContainer, HeaderTitleContainer,
  GridContainer
} from "./HeaderWithoutInstallments.styles";
import { ButtonDropdown } from "../ButtonDropdown";
import { useEffect } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { Link } from "react-router-dom";

export function HeaderWithoutInstallments() {
  const { client, getClientLogin } = useInstallments();

  const clientData = {
    name: client ? `${client.name.split(" ")[0]} ${client.name.split(" ")[client.name.split(" ").length - 1]}` : <Skeleton variant="text" width={150} />,
    cpf: client ? hiddenCpf(client.cpf) : <Skeleton variant="text" width={100} />
  }
  useEffect(()=>{
    getClientLogin()
  },[])

  return (
    <Container>
      <GridContainer>
        <HeaderTitleContainer>
          <Box sx={{ fontWeight: 500 }}>
            <Link to="/">
            <Button> <MdArrowBackIos/> Ordens de pagamentos</Button>
            </Link>
          </Box>
          <ButtonDropdown label={
            <ProfileContainer>
              <ProfileNameContainer>
                {clientData.name}
              </ProfileNameContainer>
              <ProfileCpfContainer>
                {clientData.cpf}
              </ProfileCpfContainer>
            </ProfileContainer>
          }>
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <FiLogOut />
              </ListItemIcon>
              Sair
            </MenuItem>
          </ButtonDropdown>
        </HeaderTitleContainer>
      </GridContainer>
    </Container>
  );
}
