export const TOKEN_KEY = "@feirao-Token";
export const USER_KEY = "@feirao-USER";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUser = () => JSON.parse(localStorage.getItem(USER_KEY) as string);
export const setLogin = (token:string) => {
  localStorage.setItem(TOKEN_KEY, token);
  // window.location.reload()
};

export const setUserStorage = (user:{}) => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem('@feirão-installments');
  window.location.reload()

};
