import { Button, Menu } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";

import { useState } from "react";
import { ButtonDropdownProps } from ".";


export function ButtonDropdown({ label, children }: ButtonDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        sx={{
          display: "flex",
          alignItems: "center",
          textTransform: "unset",
        }}
        onClick={handleClick}
      >
        {label}
        <span
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            fontSize: "1.4rem",
            marginLeft: "0.5rem",
          }}
        >
          <IoIosArrowDown />
        </span>
      </Button>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.11))",
            mt: 0
          },
        }}
      >
        {children}
      </Menu>
    </>
  )
}