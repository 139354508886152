import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { BottomBar } from "../../Components/BottomBar";
import { Header } from "../../Components/Header";
import { ResumBar } from "../../Components/ResumBar/ResumBar";
import { isAuthenticated } from "../../services/auth";

interface privateRouteProps {
  children: ReactNode;
}
export function PrivateRoute() {
  const auth = isAuthenticated();
  return auth ? <Outlet /> : <Navigate to="/login" />;
}

export function PrivateRouteWrapper({ children }: privateRouteProps) {
  return (
    <Box sx={{ position: "relative" }}>
      <BottomBar />
      <Header />
      {children}
      <ResumBar/>
    </Box>
  );
}
