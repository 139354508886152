import { Box } from "@mui/material";
import moment from "moment";
import { InstallmentInListProps } from ".";
import { currency } from "../../Utils";
import { CheckBox } from "../CheckBox";
import { StatusTag } from "../StatusTag";
import { CardItemLabel, CardItemValue, CheckContainer, Container } from "./InstallmentsInList.styles";

const dueDays = 180;

export function InstallmentInList({ installment, onSelect, checked = false }: InstallmentInListProps) {
  const cardItems = [
    {
      key: installment.status=== 'Pago'? "Valor pago" : "Valor",
      value: (installment.status !== "Pago" && moment(moment()).diff(installment.due_date, 'days') > dueDays) ? currency(installment.balance_value) : (installment.status=== 'Pago'? currency(installment.amortized_value) : currency(installment.total))
    },
    {
      key: installment.status=== 'Pago'? "Pago em" : "Vencimento",
      value: installment.status=== 'Pago'? moment(installment.payment_date).format('DD/MM/YYYY') : moment(installment.due_date).format('DD/MM/YYYY'),
    }
  ]
  function handleSelect(value: boolean) {
    onSelect(value);
  }

  return (
    <Container>
      <Box>
        <Box sx={{display:"flex", alignItems: 'center'}}>
          <CheckContainer ischecked={checked}>
            {(installment.status !== "Pago" && (!(moment(moment()).diff(installment.due_date, 'days') > dueDays) ) )&&
              <CheckBox checked={checked} hiddenLabel onChange={(value) => { console.log(value); handleSelect(value) }} />
            }
          </CheckContainer>

          <Box>
            {
              cardItems.map((item, key) => <Box key={key}>
                <CardItemLabel>{item.key}</CardItemLabel>
                <CardItemValue>{item.value}</CardItemValue>
              </Box>)
            }
          </Box>
        </Box>
        <Box sx={{paddingRight: '1rem'}}>
          <StatusTag status={installment.status} sx={{ width: "fit-content", marginLeft: "auto" }} />
        </Box>
      </Box>
    </Container >
  );
}
