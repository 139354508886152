import { Box, styled, Typography } from "@mui/material";

export const Container = styled(Box)`
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto;

  height: calc(100vh - 7rem - 3.3rem);
  padding: 0 1.2rem 3.2rem;
  margin: 0 auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.01);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.04);
  }
  ${(props) => props.theme.breakpoints.up("sm")} {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.01);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`;

export const PullMsg = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  overflow-y: auto;
  margin: 1rem auto;
  font-size: 1rem;
  font-weight: 500;
  opacity: 0.3;
  text-align: center;
`;
export const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column ;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 1.1rem;
  position: fixed;
  background: ${props=> props.theme.palette.background.default} ;
  z-index:2 ;
  left: 0;
  width: 100%;
  margin-top: -1px;
`;
export const InstallmentsPageTitle = styled(Typography)`
  font-weight: 500;
  opacity: 0.3;
  text-align: center;
  font-size: 1rem;
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.2rem;
  }
`;
export const InstallmentsPageTitleBox = styled(Typography)`
  font-weight: 500;
  opacity: 0.3;
  font-size: 0.9rem;
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.2rem;
  }
  text-align: center ;
  margin-bottom: 1rem;
`;
