import { Box, InputLabel, styled } from "@mui/material";
import styledSc from "styled-components";

export const Container = styled(Box)`
  padding: 0 1.5rem;
  height: 100vh;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const FormLabel = styled(InputLabel)`
  font-weight: 500;
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.3);
  margin-bottom: 0.6rem;
`;
export const FormControl = styled(Box)`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`;
export const FormInput = styledSc.input`
  border: 2px solid rgba(183, 136, 253);
  border-radius: 0.5rem;
  font-size: 1.1rem;
  padding: 0.8rem;
`;
