import { TagProps } from ".";
import { TagContainer } from "./StatusTagPayment.styles";

export function StatusTagPayment({ status="NOV", sx }: TagProps) {
  return <TagContainer status={status || 'NOV'} sx={sx ? sx : {}}>
    {status === "NOV" && "Pendente"}
    {status === "PEN" && "Aguardando aprovação"}
    {status === "CON" && "Concluído"}
    {status === "NEG" && "Negado"}
    {status === "INV" && "Dados inválidos"}
    {status === "PPN" && "Pendente de pagamento"}
  </TagContainer>
}