import { Button, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useInstallments } from "../../hooks/useInstallments";
import { currency } from "../../Utils";
import { ResumModal } from "../ResumModal";
import { Container, ContainerInside, ContainerText, LabelText, ValueText } from "./ResumBar.styles";



export function ResumBar() {
  const matches = useMediaQuery('(max-width:600px)');
  const { selectedInstallments } = useInstallments()

  const [resum, setResum] = useState<number>(0)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setResum(selectedInstallments.length > 0 ? selectedInstallments.reduce(
      (previousValue, currentValue) => previousValue + currentValue.total, 0) : 0)
  }, [selectedInstallments])

  function sendPayment() {
      setIsOpen(true)

  }

  return (
    <>
      <Container matches={matches} isInViewer={resum > 0}>
        <ContainerInside>
          <ContainerText>
            <LabelText>
              Total:
            </LabelText>
            <ValueText>
              {currency(resum)}
            </ValueText>
          </ContainerText>

          <Button onClick={() => sendPayment()} sx={{ paddingX: "2rem" }} variant="contained" color="success">
            Pagar
          </Button>

        </ContainerInside>
      </Container>
      <ResumModal total={resum} list={selectedInstallments} open={isOpen} onClose={() => setIsOpen(false)} />
    </>
  )
} 