import moment from "moment";
import { createContext, ReactNode, useContext, useState } from "react";
import api from "../services/api";
import { getUser, logout, setUserStorage } from "../services/auth";
import { installment, client, contract } from "../types";

interface InstallmentsProviderProps {
  children: ReactNode;
}

interface InstallmentsContextData {
  openInstallments: installment[] | [] | undefined;
  paidInstallments: installment[] | [] | undefined;
  installlmentsInView: number;
  changeInstallmentsInView: (view: number) => void;
  getClient: () => void;
  getClientLogin: () => void;
  getContract: (contractId: number, id: number) => void;
  contract?: contract;
  client: client | undefined;
  cleanContract: () => void;
  clearInstallments: () => void;
  closeAlert: () => void;
  alertIsOpen: boolean;

  addSelectedInstallment: (si: selectedInstallmentsProps) => void;
  removeSelectedInstallment: (si: selectedInstallmentsProps) => void;
  selectedInstallments: selectedInstallmentsProps[];
  reRenderInstallments: () => void;
  getInstallments: () => {
    openInstallments: installment[];
    paidInstallments: installment[];
    date: string;
  };
  isLoading: boolean;
}

export const InstallmentsContext = createContext<InstallmentsContextData>(
  {} as InstallmentsContextData
);

type selectedInstallmentsProps = installment;

export function InstallmentsProvider({ children }: InstallmentsProviderProps) {
  const [openInstallments, setOpenInstallments] = useState<installment[]>();
  const [paidInstallments, setPaidInstallments] = useState<installment[]>();
  const [alertIsOpen, setAlertOpen] = useState(false);
  const [installlmentsInView, setInstalllmentsInView] = useState(0);
  const [client, setClient] = useState<client>();
  const [selectedInstallments, setSelectedInstallments] = useState<
    selectedInstallmentsProps[]
  >([]);
  const [contract, setContract] = useState<contract>();
  const [isLoading, setIsLoading] = useState(false);
  function closeAlert() {
    setAlertOpen(false);
  }
  function addSelectedInstallment(si: selectedInstallmentsProps) {
    let prevInstallment = openInstallments?.find(
      (x) =>
        x.contract_id === si.contract_id &&
        x.installment_number === Number(si.installment_number) - 1
    );
    let estaMarcada = selectedInstallments.find(
      (x) => x.installment_id === prevInstallment?.installment_id
    );
    if (prevInstallment?.status !== "Pago" && prevInstallment && !estaMarcada) {
      // alert("parcela anterior não está paga");
      setAlertOpen(true);
      return;
    }
    if (
      selectedInstallments.findIndex(
        (x) => x.installment_id === si.installment_id
      ) < 0
    ) {
      setSelectedInstallments([...selectedInstallments, si]);
    }
  }
  function removeSelectedInstallment(si: selectedInstallmentsProps) {
    let findId = selectedInstallments.findIndex(
      (x) => x.installment_id === si.installment_id
    );
    let listaNaFrente = selectedInstallments.filter(
      (x) =>
        x.contract_id === si.contract_id &&
        x.installment_number > si.installment_number
    );
    console.log(listaNaFrente);
    if (findId >= 0) {
      let newSelected = selectedInstallments;
      listaNaFrente.forEach((x) =>
        newSelected.splice(
          selectedInstallments.findIndex(
            (y) => y.installment_id === x.installment_id
          ),
          1
        )
      );
      newSelected.splice(findId, 1);
      setSelectedInstallments([...newSelected]);
    }
  }

  function changeInstallmentsInView(view: number) {
    setInstalllmentsInView(view);
  }

  function getInstallments() {
    return (
      JSON.parse(localStorage.getItem("@feirão-installments") as string) || {
        openInstallments: [],
        paidInstallments: [],
        date: undefined,
      }
    );
  }

  function getClient() {
    setIsLoading(true);
    setClient(undefined);
    setOpenInstallments(undefined);
    setPaidInstallments(undefined);
    let now = moment();
    let timeCache = now.diff(moment(getInstallments().date), "seconds");
    console.log(timeCache);
    console.log(getInstallments());
    if (timeCache >= 2 || !getInstallments().date) {
      api
        .get(`/api/clients`)
        .then((resp) => {
          setUserStorage({
            birthDate: resp.data.birthDate,
            cpf: resp.data.cpf,
            id: resp.data.id,
            name: resp.data.name,
          });
          setClient({
            birth_date: resp.data.birthDate as string,
            cpf: resp.data.cpf,
            id: resp.data.id,
            name: resp.data.name,
          });

          const open = resp.data.open_installments.map((x: installment) =>
            x.expired_days > 0
              ? {
                  ...x,
                  installment_id: `${x.installment_number}_${x.contract_id}`,
                  status: "Vencida",
                }
              : {
                  ...x,
                  installment_id: `${x.installment_number}_${x.contract_id}`,
                  status: "Em aberto",
                }
          );
          let paid: installment[] = resp.data.paid_installments.map(
            (x: installment) => ({
              ...x,
              status: "Pago",
              installment_id: `${x.installment_number}_${x.contract_id}`,
            })
          );
          paid = paid.sort(function (a, b) {
            return moment(b.payment_date).diff(moment(a.payment_date));
          });
          console.log(open);
          setOpenInstallments(open);
          setPaidInstallments(paid);
          localStorage.setItem(
            "@feirão-installments",
            JSON.stringify({
              openInstallments: open,
              paidInstallments: paid,
              date: resp.data.updatedAt,
            })
          );
        })
        .catch((err) => {
          if (err.response?.data.statusCode === 401) {
            logout();
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
      setTimeout(() => {
        setOpenInstallments(getInstallments().openInstallments);
        setPaidInstallments(getInstallments().paidInstallments);
        setClient(getUser());
      }, 500);
    }
  }
  function getClientLogin() {
    setIsLoading(true);
    setClient(undefined);
    setOpenInstallments(undefined);
    setPaidInstallments(undefined);
    let now = moment();
    let timeCache = now.diff(moment(getInstallments().date), "seconds");
    console.log(timeCache);
    console.log(getInstallments());
    if (timeCache >= 2 || !getInstallments().date) {
      api
        .get(`/api/clients?cache=true`)
        .then((resp) => {
          setUserStorage({
            birthDate: resp.data.birthDate,
            cpf: resp.data.cpf,
            id: resp.data.id,
            name: resp.data.name,
          });
          setClient({
            birth_date: resp.data.birthDate as string,
            cpf: resp.data.cpf,
            id: resp.data.id,
            name: resp.data.name,
          });

          const open = resp.data.open_installments.map((x: installment) =>
            x.expired_days > 0
              ? {
                  ...x,
                  installment_id: `${x.installment_number}_${x.contract_id}`,
                  status: "Vencida",
                }
              : {
                  ...x,
                  installment_id: `${x.installment_number}_${x.contract_id}`,
                  status: "Em aberto",
                }
          );
          let paid: installment[] = resp.data.paid_installments.map(
            (x: installment) => ({
              ...x,
              status: "Pago",
              installment_id: `${x.installment_number}_${x.contract_id}`,
            })
          );
          paid = paid.sort(function (a, b) {
            return moment(b.payment_date).diff(moment(a.payment_date));
          });
          setOpenInstallments(open);
          setPaidInstallments(paid);
          localStorage.setItem(
            "@feirão-installments",
            JSON.stringify({
              openInstallments: open,
              paidInstallments: paid,
              date: resp.data.updatedAt,
            })
          );
        })
        .catch((err) => {
          if (err.response?.data.statusCode === 401) {
            logout();
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
      setTimeout(() => {
        setOpenInstallments(getInstallments().openInstallments);
        setPaidInstallments(getInstallments().paidInstallments);
        setClient(getUser());
      }, 500);
    }
  }

  function getContract(contractId: number, id: number) {
    api
      .get(`/api/contracts/${contractId}`)
      .then((response) => {
        let parcelas = response.data.installments;
        // const open = parcelas.map((x: installment) => x.expired_days > 0 ? { ...x, installment_id: `${x.installment_number}_${x.contract_id}`, status: 'Vencida' } : { ...x, installment_id: `${x.installment_number}_${x.contract_id}`, status: "Em aberto" })
        const paid = parcelas.map((x: installment) => ({
          ...x,
          status:
            x.total === 0
              ? "Pago"
              : x.expired_days > 0
              ? "Vencida"
              : "Em aberto",
          installment_id: `${x.installment_number}_${x.contract_id}`,
        }));
        // parcelas.map((i: installment) => i.installment_id = `${i.installment_number}_${i.contract_id}`)
        setContract({ ...response.data, installments: [...paid] });
      })
      .catch((err) => {
        if (err.response.data.statusCode === 401) {
          logout();
        }
      });
  }

  function cleanContract() {
    setContract(undefined);
  }

  function reRenderInstallments() {
    let newInst = openInstallments;
    if (newInst) {
      setOpenInstallments([...newInst]);
    }
  }

  function clearInstallments() {
    setSelectedInstallments([]);
  }
  return (
    <InstallmentsContext.Provider
      value={{
        paidInstallments,
        openInstallments,
        installlmentsInView,
        changeInstallmentsInView,
        getClient,
        getContract,
        contract,
        client,
        cleanContract,
        addSelectedInstallment,
        removeSelectedInstallment,
        selectedInstallments,
        reRenderInstallments,
        isLoading,
        getInstallments,
        getClientLogin,
        alertIsOpen,
        closeAlert,
        clearInstallments
      }}
    >
      {children}
    </InstallmentsContext.Provider>
  );
}

export function useInstallments() {
  const context = useContext(InstallmentsContext);
  return context;
}
