import MuiCard from "@mui/material/Card";
import { CardProps } from "./Card.types";

export function Card({ children, ...rest }: CardProps) {
  return (
    <MuiCard
      {...rest}
      style={{
        boxShadow: "0px 4px 19px 3px rgba(0, 0, 0, 0.06)",
        borderRadius: "10px",
      }}
    >
      {children}
    </MuiCard>
  );
}
