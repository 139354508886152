import {
  Box,
  useMediaQuery,
} from "@mui/material";
import logo from "../../Assets/logo-slogan.png";

export function BottomBar() {
    const matches = useMediaQuery('(max-width:600px)');

  return (
    <Box
    sx={{
        width: "100%",
        height: "3.3rem",
        position:  matches ?  "fixed" : "relative",
        display: "flex",
        color: "secondary.main",
        alignItems: "center",
        justifyContent: "center",
        bottom: matches ? 0 : "initial",
        backgroundColor: "primary.main",
        overflow: "hidden",
        zIndex:"999999999999"
    }}
    >
      <img
        src={logo}
        alt={"logo feirao dos moveis"}
        style={{ maxWidth: "181px", width: "90%" }}
      />
    </Box>
  );
}
