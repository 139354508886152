import { Box, styled, Typography } from "@mui/material";

export const Container = styled(Box)`
  padding: 1rem 0;
  align-items: center;
  border-bottom: 1px solid rgba(56, 16, 115, 0.1);
  width: 100%;
  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const CardItemLabel = styled(Typography)`
  font-weight: 500;
  font-size: 0.75rem;
  opacity: 0.3;

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1rem;
  }
`;

export const CardItemValue = styled(Typography)`
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  color: ${(props) => props.theme.palette.primary.main};
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.2rem;
  }
`;
export const CheckContainer = styled(Box)<{ ischecked: boolean }>`
  align-items: center;
  display: flex;
  width: fit-content ;
  min-width: 3rem ;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  color: ${(props) =>
    props.ischecked ? props.theme.palette.success.main : "#E0E0E0"};
  font-weight: 500;
  font-size: 2rem;
  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.5rem;
  }
`;
