import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

const GlobalStyles = createGlobalStyle`
  body {
    background:${theme.palette.background.default};
    height: 100%;

  }
  #root{
    height: 100%;
    overflow: hidden;
  }
  html{
    @media (max-width: 1080px) {
    font-size: 93.75%;
    height: 100%;
  }
  @media (max-width: 720px) {
    font-size: 87.5%;
  }
  }

  `;

export default GlobalStyles;
