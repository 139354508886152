import { Box, ListItemIcon, MenuItem } from "@mui/material";
import { FiDollarSign, FiLogOut } from "react-icons/fi";
import { Tabs } from "../Tabs";
import { logout } from "../../services/auth";
import { useInstallments } from "../../hooks/useInstallments";
import { hiddenCpf } from "../../Utils";
import Skeleton from "@mui/material/Skeleton";
import {
  Container,
  ProfileCpfContainer, ProfileNameContainer,
  ProfileContainer, HeaderTitleContainer,
  GridContainer, TabsContainer
} from "./Header.styles";
import { ButtonDropdown } from "../ButtonDropdown";
import { useNavigate } from "react-router-dom";

export function Header() {
  const { client } = useInstallments();
  const navigate = useNavigate()
  const clientData = {
    name: client ? `${client.name.split(" ")[0]} ${client.name.split(" ")[client.name.split(" ").length - 1]}` : <Skeleton variant="text" width={150} />,
    cpf: client ? hiddenCpf(client.cpf) : <Skeleton variant="text" width={100} />
  }

  return (
    <Container>
      <GridContainer>
        <HeaderTitleContainer>
          <Box sx={{ fontWeight: 500 }}>Minhas faturas</Box>
          <ButtonDropdown label={
            <ProfileContainer>
              <ProfileNameContainer>
                {clientData.name}
              </ProfileNameContainer>
              <ProfileCpfContainer>
                {clientData.cpf}
              </ProfileCpfContainer>
            </ProfileContainer>
          }>
            <MenuItem onClick={()=>navigate('/ordem-de-pagamento/')}>
              <ListItemIcon>
                <FiDollarSign />
              </ListItemIcon>
              Ordens pagamentos
            </MenuItem>
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <FiLogOut />
              </ListItemIcon>
              Sair
            </MenuItem>
          </ButtonDropdown>
        </HeaderTitleContainer>
        <TabsContainer>
          <Tabs tabs={["Em aberto", "Pagas"]}></Tabs>
        </TabsContainer>
      </GridContainer>
    </Container>
  );
}
