import { Button, styled, Typography } from "@mui/material";

export const HeaderItemsLabel = styled(Typography)`
  font-weight: 500;
  font-size: 1rem;
  opacity: 0.3;
`;

export const HeaderItemsValue = styled(Typography)`
  font-weight: 700;
  font-size: 1.2rem;
  color: ${(props) => props.theme.palette.primary.main};
`;
export const CloseButton = styled(Button)`
  font-size: 1.5rem;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.3rem;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  color: ${(props) => props.theme.palette.primary.main};
  padding: 0;
`;
