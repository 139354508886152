import { Box, Button, styled, Typography } from "@mui/material";

export const cardContainerStyle = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "2rem",
  // border: "2px solid",
  transition: "0.2s",
  position: 'relative',
};

export const MenuButton = styled(Button)`
  font-size: 1.5rem;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  border-radius: 0.3rem;
  border: 1px solid;
  border-color: ${(props) => props.theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  color: ${(props) => props.theme.palette.primary.main};
  padding: 0;
`;

interface HeaderProps {
  isChecked: boolean;
}

export const CardHeader = styled(Box)<HeaderProps>`
  display: flex;
  font-size: 1.3rem;
  color: ${(props) =>
    props.isChecked ? props.theme.palette.success.main : "#E0E0E0"};
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
  padding: 0 13px ;
`;

export const CardItemLabel = styled(Typography)`
  font-weight: 500;
  font-size: 1rem;
  opacity: 0.3;
`;

export const CardItemValue = styled(Typography)`
  font-weight: 700;
  font-size: 1.2rem;
  color: ${(props) => props.theme.palette.primary.main};
`;

export const TopAlert = styled(Box)`
  width: 100%;
  background-color: #C2403629;
  margin: 0 0 10px ;
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #C24036;
  text-align: center;
    font-weight: 600;
    font-size: 0.9rem ;
`;
export const TopAlertDiscount = styled(Box)`
  width: 100%;
  background-color: #438C4329;
  margin: 0 0 10px;
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #438C43;
  text-align: center;
    font-weight: 600;
    font-size: 0.9rem ;
`;