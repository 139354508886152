import React, { useEffect, useState } from "react";
import { installment } from "../../types";
import { currency } from "../../Utils";
import {
  Container,
  DialogTitle,
  PullMsg,
  Carddetail,
  ContainerTitle,
  ContainerResum,
  TotalValue,
  TotalValueText,
  TitleContainer,
  InstallmentsPageTitle,
} from "./PaymentOrderList.styles";
import PullToRefresh from "react-simple-pull-to-refresh";
import { Button } from "@mui/material";
import { BottomBar } from "../../Components/BottomBar";
import { HeaderWithoutInstallments } from "../../Components/HeaderWithoutInstallments";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import { StatusTagPayment } from "../../Components/StatusTagPayment";
import { Link } from "react-router-dom";
import { PaymentModal } from "../../Components/PaymentModal";

interface payment {
  client_id: number;
  createdAt: string;
  installments: installment[];
  payment_amount: number;
  payment_method: "CREDIT_CARD" | "DEBIT_CARD" | "PIX" | "BILLET";
  updatedAt: string;
  status: "NOV" | "PEN" | "CON" | "NEG" | "PPN";
  order_id: number;
  _id: string;
}

export function PaymentOrderList() {
  const { paymentId } = useParams();
  const [payment, setPayment] = useState<payment[]>();
  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<payment | null>(null);
  useEffect(() => {
    // getClientLogin();
    api.get(`api/payments/`).then((resp) => {
      console.log(resp.data);
      setPayment(resp.data);
    });
  }, [paymentId]);

  return (
    <>
      <BottomBar />
      <HeaderWithoutInstallments />
      <PullToRefresh
        onRefresh={() =>
          new Promise(function (resolve, reject) {
            // do a thing, possibly async, then…
            // getClient()
            api.get(`api/payments/`).then((resp) => {
              console.log(resp.data);
              setPayment(resp.data);
            });
            if (true) {
              resolve("Stuff worked!");
            } else {
              reject(Error("It broke"));
            }
          })
        }
        pullDownThreshold={85}
        resistance={2.5}
        pullingContent={<PullMsg>Puxe para atualizar!</PullMsg>}
      >
        <Container>
          {payment?.length === 0 && (
            <TitleContainer>
              <InstallmentsPageTitle>
                {/* Última atualização em : 03/06/2022 ás 18:32 */}
                Você não possui nenhuma ordem de pagamento.
              </InstallmentsPageTitle>
            </TitleContainer>
          )}
          {payment?.map((x, k) => (
            <Carddetail key={x._id}>
              <ContainerTitle>
                <DialogTitle>
                  Ordem n° {x.order_id} <StatusTagPayment status={x.status} />
                </DialogTitle>
              </ContainerTitle>
              <ContainerResum></ContainerResum>
              <TotalValue>
                <TotalValueText>
                  Total: {currency(x.payment_amount)}
                </TotalValueText>
                <TotalValueText></TotalValueText>
              </TotalValue>
              {x.status !== "CON" && x.status !== "NEG" ? (
                <Link to={`${x._id}`} style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{ textDecoration: "none", mt: "1rem" }}
                  >
                    Pagar
                  </Button>
                </Link>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setSelectedPayment(x);
                    setOpen(true);
                  }}
                  sx={{ textDecoration: "none", mt: "1rem" }}
                >
                  Ver detalhes
                </Button>
              )}
            </Carddetail>
          ))}
        </Container>
      </PullToRefresh>
      <PaymentModal
        open={open}
        onClose={() => setOpen(false)}
        payment={selectedPayment}
      />
    </>
  );
}
