import React, { useEffect} from "react";
import { Back, Card, FlipperContainer, Front } from "./CreditCardInput.styles";
import visaLogo from "../../Assets/visa.jpeg"
import mastercardLogo from "../../Assets/mastercard.jpeg"
import dinersLogo from "../../Assets/diners.jpeg"
import amxLogo from "../../Assets/amex.png"
import discoverLogo from "../../Assets/discover.png"
import hipercardLogo from "../../Assets/hipercard.jpg"
import eloLogo from "../../Assets/elo.webp"
import jcbLogo from "../../Assets/jcb.png"
interface CreditCardInputProps {
  values: {
    name: string;
    number: string;
    valid: string;
    cvv: string;
  };
  pos: boolean;
}

const Logos = {
  visa: visaLogo, 
  mastercard: mastercardLogo, 
  diners: dinersLogo, 
  amex: amxLogo, 
  discover: discoverLogo, 
  hipercard: hipercardLogo, 
  elo: eloLogo, 
  jcb: jcbLogo, 
}

type cards = 'visa' |"mastercard" |"diners" |"amex" |"discover" |"hipercard" |"elo" |"jcb"

export const CreditCardInput: React.FC<CreditCardInputProps> = ({
  values,
  pos,
}) => {
  function cardValidate(cardnumber:string) {
    cardnumber = cardnumber.replace(/[^0-9]+/g, "");

    var cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^5[1-5][0-9]{14}/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      amex: /^3[47][0-9]{13}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      // aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    };

    for (var flag in cards) {
      if (cards[flag as cards ].test(cardnumber)) {
        return flag;
      }
    }

    return false;
  }
  useEffect(()=>{
    // cardValidate(values.number)
  }, [values.number])

  return (
    <FlipperContainer>
      <div className={`flipper ${pos ? "flipped" : ""}`}>
        <Front className="front">
          <Card topPos={true}>
            <div className="name">{values.name}</div>
            <div className="number">{values.number}</div>

            <div className="bottom">
              <div className="list">
                <div>
                  <span>VALIDADE</span>
                  <span>{values.valid}</span>
                </div>
              </div>
              <img height="auto" width="40px" src={Logos[cardValidate(values.number) as cards]} alt={cardValidate(values.number) as cards} />
            </div>
          </Card>
        </Front>
        <Back className="back">
          <Card>
            <div className="blackList"></div>
            <div className="cvv">{values.cvv}</div>
            <div></div>
          </Card>
        </Back>
      </div>
    </FlipperContainer>
  );
};
