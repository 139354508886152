import { createContext, ReactNode, useContext, useState } from "react";
import api from "../services/api";
import { setLogin } from "../services/auth";

interface LoginProviderProps {
  children: ReactNode;
}

interface LoginContextData {
  login: (cpf: string, birth_date: string) => void;
  isLoading: boolean;
  errorMsg: string;
}

export const LoginContext = createContext<LoginContextData>(
  {} as LoginContextData
);

export function LoginProvider({ children }: LoginProviderProps) {
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function isValidCPF(cpf: string) {
    if (typeof cpf !== 'string') return false
    cpf = cpf.replace(/[^\d]+/g, '')
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
    let cpfArr: string[] = cpf.split('')
    const validator = cpfArr
      .filter((digit, index, array) => index >= array.length - 2 && digit)
      .map(el => +el)
    const toValidate = (pop: number) => cpfArr
      .filter((digit: any, index: number, array: string | any[]) => index < array.length - pop && digit)
      .map((el: string | number) => +el)
    const rest = (count: number, pop: number) => (toValidate(pop)
      .reduce((soma: number, el: number, i: number) => soma + el * (count - i), 0) * 10) % 11 % 10
    return !(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1])
  }

  function login(cpf: string, birth_date: string) {
    setIsLoading(true)
    console.log({ cpf, birth_date })
    if (cpf.length === 14) {
      if (isValidCPF(cpf)) {
        api
          .post("/api/clients/login", { cpf, birth_date })
          .then((response) => {
            setLogin(response.data.acess_token);
            window.location.reload();
            setIsLoading(false)
          })
          .catch((err) => {
            setIsLoading(false)
            console.log(err.response);
            setErrorMsg(err.response.data.message)
            if (err.response.status === 500) {
              setErrorMsg(err.response.data.message)

            }
          });
      } else {
        setIsLoading(false)
        setErrorMsg("Digite um cpf válido!")
      }
    } else {
      api
        .post("/api/clients/login", { cpf, birth_date: ' ' })
        .then((response) => {
          setLogin(response.data.acess_token);
          window.location.reload();
          setIsLoading(false)
        })
        .catch((err) => {
          setIsLoading(false)
          console.log(err.response);
          setErrorMsg(err.response.data.message)
          if (err.response.status === 500) {
            setErrorMsg(err.response.data.message)

          }
        });
    }


  }

  return (
    <LoginContext.Provider
      value={{
        login,
        errorMsg,
        isLoading
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export function useLogin() {
  const context = useContext(LoginContext);
  return context;
}
