import { styled, Box } from "@mui/material";
import { TagProps } from ".";

export const config = {
  Vencida: {
    bg: "rgba(194, 64, 54, 0.16)",
    c: "#C24036",
  },
  "Em aberto": {
    bg: "rgb(60, 63, 140, 0.16)",
    c: "#3B3F8C",
  },
  Pago: {
    bg: "rgba(67, 140, 67, 0.16)",
    c: "#438C43",
  },
};
export const TagContainer = styled(Box)<TagProps>`
  background-color: ${(props) => config[props.status].bg};
  color: ${(props) => config[props.status].c};
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 500;
  padding: 7px 11px;
  min-width: 6.25rem;
  text-align: center;
`;
