import { Box } from "@mui/system";
import styled from "styled-components";

export const FlipperContainer = styled(Box)`
  float: left;
  width: 100%;
  max-width: 290px;
  height: 170px;

  margin-right: 15px;
  display: block;

  span {
    color: white;
  }

  > div.flipper {
    float: left;
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;

    .front,
    .back {
      float: left;
      display: block;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      transform-style: preserve-3d;
      transition: -webkit-transform ease 500ms;
      transition: transform ease 500ms;
    }

    .front {
      z-index: 2;

      /* front tile styles go here! */
    }

    .back {
      /* back tile styles go here! */
    }
  }

  .front {
    transform: rotateX(0deg);
  }
  .back {
    transform: rotateX(-180deg);
  }

  div.flipper.flipped {
    .front {
      transform: rotateX(180deg);
    }
    .back {
      transform: rotateX(0deg);
    }
  }
`;
export const Front = styled(Box)``;
export const Back = styled(Box)``;
export const Card = styled(Box)<{ topPos?: boolean }>`
  width: 100%;
  max-width: 290px;
  height: 170px;
  background: rgb(60, 64, 140);
  background: linear-gradient(
    135deg,
    rgba(60, 64, 140, 1) 0%,
    rgba(14, 17, 77, 1) 100%
  );
  border-radius: 25px;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: ${(props) => (props.topPos ? "space-between" : "flex-start")};
  flex-direction: column;
  color: white;

  .number {
    font-size: 1.2rem;
  }
  .name {
    text-transform: uppercase;
  }

  .bottom {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  .list {
    display: flex;

    > div {
      flex-direction: column;
      display: flex;
      span:first-child {
        font-size: 0.8rem;
        opacity: 0.7;
      }

      + div {
        margin-left: 1rem;
      }
    }
  }
  .blackList {
    background-color: #000;
    width: calc(100% + 3rem);
    height: 2.2rem;
    margin: 1rem -1.5rem 0;
  }
`;
