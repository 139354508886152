import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    background: { default: "#F2F2F2" },
    primary: {
      main: "#3C3F8C",
      dark: "#403D3E",
    },
    success:{
      main:"#609E60"
    }
  },
  typography: {
    fontFamily: "Roboto",
    fontWeightBold: 500,
    h1: {
      fontSize: "1rem",
      fontWeight: "500",
      lineHeight: "1.2em",
    },
    h2: {
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.2em",
    },
  },
});
