import { Alert, Box, Button, FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { FormEvent, useState } from "react"
import logo from "../../Assets/logo.png"
import { Card } from "../../Components/Card/Card"
import { useLogin } from "../../hooks/useLogin"
import { cnpjMask, cpfMask, dateMask } from "../../Utils"
import { Container, FormControl, FormInput, FormLabel } from "./Login.styles"
export function Login() {
  const [cpf, setCpf] = useState("");
  const [birth_date, setBirth_date] = useState("");
  const [loginType, setLoginType] = useState("CPF");
  const { login, errorMsg, isLoading } = useLogin();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginType((event.target as HTMLInputElement).value);
    setCpf('')
  };


  function sendLogin(event: FormEvent) {
    event.preventDefault();
    let sendDate = `${birth_date.split("/")[2]}-${birth_date.split("/")[1]}-${birth_date.split("/")[0]}`
    login(cpf, sendDate);
  }
  return (
    <Container>
      <Card sx={{ maxWidth: "700px", width: "100%" }}>
        <>
          <Box sx={{ display: "flex", marginY: "2rem" }}>
            <img
              src={logo}
              alt={"logo feirao dos moveis"}
              style={{ maxWidth: "181px", width: "90%", margin: "auto" }}
            />
          </Box>
          {errorMsg && (
            <Box style={{ padding: "1rem" }}>
              <Alert sx={{ fontSize: "1rem" }} severity="error">{errorMsg}</Alert>
            </Box>
          )}

          <form style={{ padding: "0 1rem" }} onSubmit={sendLogin}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleRadioChange}
                value={loginType}
              >
                <FormControlLabel value="CPF" control={<Radio />} label="CPF" />
                <FormControlLabel value="CNPJ" control={<Radio />} label="CNPJ" />
              </RadioGroup>
            </FormControl>
           
            {
              loginType === 'CPF' ?
              <><FormControl>
                  <FormLabel>
                    CPF:
                  </FormLabel>
                  <FormInput
                    type="tel"
                    value={cpf}
                    onChange={(event) => setCpf(cpfMask(event.target.value))}
                    placeholder={"000.000.000-00" }
                    required />
                </FormControl><FormControl>
                    <FormLabel>
                      Data de nascimento:
                    </FormLabel>
                    <FormInput
                      type="tel"
                      value={birth_date}
                      placeholder="DD/MM/AAAA"
                      onChange={(event) => setBirth_date(dateMask(event.target.value))}
                      required />
                  </FormControl></> : 
                  <FormControl>
                  <FormLabel>
                    CNPJ:
                  </FormLabel>
                  <FormInput
                    type="tel"
                    value={cpf}
                    onChange={(event) => setCpf(cnpjMask(event.target.value))}
                    placeholder={"00.000.000/0001-00"}
                    required />
                </FormControl>
            }
            <Button
              type="submit"
              disabled={isLoading}
              sx={{ marginBottom: "2rem", mt: "1rem" }}
              variant="contained"
              fullWidth
              size="large"
            >
              {isLoading ? "Carregando seus dados..." : "Entrar"}
            </Button>
          </form>
        </>
      </Card>
    </Container>
  );
}
