import { Button, Dialog, Box, Alert } from "@mui/material";
import { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { currency } from "../../Utils";
import {
  CloseButton,
  TotalValue,
  TotalValueText,
  DialogTitle,
  ContainerTitle,
  ContainerResum,
  HeaderItemsLabel,
  ResumItem,
} from "./ResumModal.styles";
import { ResumModalProps } from ".";
import api from "../../services/api";
import { useInstallments } from "../../hooks/useInstallments";
import { SelectCard } from "../SelectCard";
import { useNavigate } from "react-router-dom";

export function ResumModal({ open, onClose, list, total }: ResumModalProps) {
  const handleClose = () => {
    onClose();
  };
  let navigate = useNavigate();
  const { client, clearInstallments } = useInstallments();
  const [payment_method, setPayment_method] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSend = () => {
    setLoading(true);
    setErrorMsg("")
    api
      .post("api/payments/create", {
        installments: list.map((x) => ({
          contract_id: x.contract_id,
          installmentNumber: x.installment_number,
          store_id: x.store_id,
        })),
        paymentMethod: payment_method,
        payment_amount: total,
        clientId: client?.id,
      })
      .then((resp) => {
        clearInstallments()
        navigate(`ordem-de-pagamento/${resp.data._id}`);
      })
      .catch((err) => {
        setErrorMsg(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="contract-dialog-title"
        aria-describedby="contract-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <ContainerTitle>
          <DialogTitle>Resumo:</DialogTitle>
          <CloseButton onClick={handleClose}>
            <MdOutlineClose />
          </CloseButton>
        </ContainerTitle>
        <ContainerResum>
          {list?.map((item, key) => (
            <ResumItem key={key}>
              <HeaderItemsLabel>Parcela {item.installment_id}</HeaderItemsLabel>
              <HeaderItemsLabel>{currency(item.total)}</HeaderItemsLabel>
            </ResumItem>
          ))}
        </ContainerResum>
        <TotalValue>
          <TotalValueText>Total:</TotalValueText>
          <TotalValueText>{currency(total)}</TotalValueText>
        </TotalValue>
        <SelectCard onSelect={(value) => setPayment_method(value)} />
        <Box paddingX={2}>
          {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        </Box>
        <Box
          sx={{ padding: "1rem", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            disabled={!payment_method || loading}
            onClick={handleSend}
            variant="contained"
            color="primary"
          >
            Continuar para o pagamento
          </Button>
        </Box>
      </Dialog>
    </div>
  );
}
