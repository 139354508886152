import { Box, styled } from "@mui/material";

export const Container = styled(Box)`
  background-color: #fff;
  width: 100%;
  box-shadow: "0px 4px 19px 3px rgba(0, 0, 0, 0.06)";
  // position: "fixed",
  position: relative;
`;

export const GridContainer = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 43.75rem;
  margin: 0 auto;
`;

export const HeaderTitleContainer = styled(Box)`
  color: ${(props) => props.theme.palette.primary.main};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;
export const TabsContainer = styled(Box)`
  padding: 0 2rem;
`;

export const ProfileContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const ProfileNameContainer = styled(Box)`
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  align-items: flex-end;
  line-height: 1;
`;
export const ProfileCpfContainer = styled(Box)`
  font-weight: 400;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
  line-height: 1;
`;
